import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './css/PP_Section1.css';

const Background_Slideshow = ({productID}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fonction pour récupérer les données depuis le backend
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://stratup1.azurewebsites.net/get_products?PRODUCT_ID=' + productID, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json(); // Attendre que la promesse soit résolue
        setPhotos(data[0].PHOTOS.split(","))

      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [productID]);


  useEffect(() => {
    if (photos.length > 0) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % photos.length);
      }, 7000); // Change d'image toutes les 7 secondes

      return () => clearInterval(interval);
    }
  }, [photos.length, productID]);

  useEffect(() =>{
      setActiveIndex(0)
  },[productID])

  if (loading || !Array.isArray(photos) || photos.length === 0) {
    return null; // ou return <div>Loading...</div> pour afficher un message de chargement
  }

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  return (
    <div className="background-slideshow">
      {photos.map((image, index) => {
        return (<img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          className={index === activeIndex ? 'active' : ''}
        />
        );
      })}
      <button className="arrow left" onClick={handlePrevClick}>
        <LeftOutlined />
      </button>
      <button className="arrow right" onClick={handleNextClick}>
        <RightOutlined />
      </button>
    </div>
  );
};

export default Background_Slideshow;
