// src/components/InvestTab.js
import React, { useState, useContext } from 'react';
import { Button, message } from 'antd';
import { AuthContext } from '../AuthContext/AuthContext';
import './css/PP_Section1.css'
 

const InvestTab = ({productID}) => {
  const { userEmail } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  console.log('userEmail = ', userEmail)
  console.log('productID = ', productID)

  const handleSubmit = async () => {
    if (!userEmail) {
      message.error('Veuillez vous connecter pour vous inscrire à la liste d\'attente.');
      return;
    }

    setLoading(true);

    try {
      // Envoie de l'email à votre backend pour l'inscription à la liste d'attente
      const response = await fetch('https://stratup1.azurewebsites.net/add_to_waitlist', {  // http://localhost:5000/add_to_waitlist
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail, productID: productID }),
      });

      if (!response.ok) {
        throw new Error('Failed to subscribe to the waitlist.');
      }

      message.success('Vous avez été inscrit à la liste d\'attente !');
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '10%' }}>
      <h2>Réserve ta place sur cette opportunité</h2>
      <p>Ce projet est en cours de développement, mais vous pouvez vous inscrire à la liste d'attente pour être sûr de pouvoir investir lorsque l'objet sera mis en vente.</p>
      <Button className='CTA' type="text" loading={loading} onClick={handleSubmit}>
        M'inscrire à la liste d'attente
      </Button>
    </div>
  );
};

export default InvestTab;
