// How it works
import React, {useEffect, useState, useContext } from 'react';
import './css/PP_Section1.css';
//import './App.css'; // Assurez-vous de créer un fichier CSS séparé pour votre style
import PpBackgroundSlideshow from './PpBackgroundSlideshow.js';
import { Row, Col, Tabs, Button } from 'antd';
import {LeftSquareOutlined} from '@ant-design/icons';
import { AuthContext } from '../AuthContext/AuthContext';
import InvestTab from './InvestTab.js';


const Section1 = ({productID, handleOpenPopup, handleClosePopup, toggleSider}) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSiderVisible, setSiderVisibility] = useState(false);



  useEffect(() => {
    // Fonction pour récupérer les données depuis le backend
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://stratup1.azurewebsites.net/get_products?PRODUCT_ID=' + productID, { //https://stratup1.azurewebsites.net
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          }
      })
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json(); // Attendre que la promesse soit résolue
        setProducts(data); // Mettre à jour l'état avec les données résolues
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [productID]);

  if (loading) {
    return <div> Loading...</div>
  }

  const tabItems = [
    {
      label: 'Resume',
      key: '1',
      children: (
        <div style={{padding:'10%'}}>
             <p >{products[0].DESCRIPTION}</p> 
            <h2>Actuellement en phase de {products[0].STAGE}, 
            {products[0].PRICE ? ' évalué à ' + products[0].PRICE.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits:0 })
            : 'Prix non disponible'}
            </h2>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className='CTA' type="text" onClick={handleOpenPopup}>Investir</Button>
            </div>
          </div>
      ),
    },
    {
      label: 'Finance',
      key: '2',
      children: (
        <div style={{padding:'10%'}}>
          <h2>Informations Financières</h2>
          <p>Voici des informations financières détaillées pour l'onglet 2.</p>
          <ul>
            <li>Historique des prix</li>
            <li>ROI potentiel</li>
            <li>Montant levé</li>
          </ul>
        </div>
      ),
    },
    {
      label: 'Histoire',
      key: '3',
      children: (
        <div style={{padding:'10%'}}>
          <h2>Histoire de l'Œuvre</h2>
          <div style={{marginBottom: '20px'}}>
              <h3>Contexte de Création</h3>
              <p>Inspirée par la militarisation et la paix mondiale en 2005. Réalisée dans un lieu secret, typique du style furtif de Banksy.</p>
          </div>
          <div>
              <h3>Réception Publique</h3>
              <p>L'œuvre a été louée pour son message puissant contre la guerre, provoquant de nombreux débats et articles de presse.</p>
          </div>
        </div>
      ),
    },
    {
      label: 'Legal',
      key: '4',
      children: (
        <div style={{padding:'10%'}}>
          <h2>Informations Légales</h2>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent : 'space-between'}}>
              <div style={{flex: 1, minWidth : '300px', margin: '10px'}}>
                  <h2>Authentification</h2>
                  <p>Certificat d'authenticité fourni par Pest Control. Validée par des experts reconnus du marché de l'art.</p>
              </div>
              <div style={{flex: 1, minWidth : '300px', margin: '10px'}}>
                  <h2>Propriété</h2>
                  <p>Liste des précédents propriétaires ou collectionneurs de l'œuvre. Vente privée en 2006, enchère chez Sotheby's en 2018.</p>
              </div>
              <div style={{flex: 1, minWidth : '300px', margin: '10px'}}>
                  <h2>Assurance</h2>
                  <p>Valeur Assurée : 25,000 €. Assurance couvrant les dommages et le vol.</p>
              </div>
          </div>
        </div>
      ),
    }
  ]

  // Ajoutez une tabulation conditionnelle si l'utilisateur est authentifié
  if (isAuthenticated) {
    tabItems.unshift({
      label: 'Investir',
      key: '0',
      children: (
        <InvestTab productID ={productID}/>
      ),
    });
  }
  

  const isSmallScreen = window.matchMedia('(max-width:850px)').matches || (window.matchMedia("(orientation: portrait)").matches) 

  return (
    <Row className="PP_Section1">
      <Col span={0}/>
      <Col span={isSmallScreen ? 24 : 12}>
        {isSmallScreen && (
          <Row>
            <Col span={2}>
              <Button onClick={toggleSider} style={{ marginTop: '10px', background: 'none',border: 'none' }}>
                <LeftSquareOutlined color='#004343'/>
              </Button>
            </Col>
            <Col span={20}>
              <h1>{products[0].NAME} - {products[0].ARTIST}</h1>
            </Col>
          </Row>
        )
      }        
        <PpBackgroundSlideshow productID={productID} loading="lazy"/>
      </Col>
      <Col span={isSmallScreen ? 22 : 11}>
      {!isSmallScreen && <h1>{products[0].NAME} - {products[0].ARTIST}</h1>}
        <Tabs
            type="card"
            items={tabItems}
            className="custom-tabs"
            tabPosition="top"
            centered
          />
      </Col>
    </Row>
);
}
export default Section1;
