import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LpMain from './Components/LandingPage/LpMain';
import PpMain from './Components/ProductPage/PpMain';
import PpMainSC from './Components/ProductPage_SC/PpMainSC';
import facebook from './optimized_media/mail_facebook_logo.avif';
import x from './optimized_media/mail_x_logo.avif';
import insta from './optimized_media/mail_insta_logo.avif';
import linkedin from './optimized_media/mail_linkedin_logo.avif';
import logo from './optimized_media/logo_w_background.avif'
import { AuthProvider } from './Components/AuthContext/AuthContext';
import VerificationMailPopUp from './Components/VerificationMailPopUp/VerificationMailPopUp';

import TestSC from './Components/TestSC/TestSC';


const App =() => {
    const [token, setToken] = useState(localStorage.getItem('token') || '');
  
    useEffect(() => {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    }, [token]);
  
    return (
        <Router>
            <AuthProvider>
            <Routes>
            <Route exact path="/" element={<LpMain token={token} setToken={setToken}/>} />
            <Route path="/reset_password/" element={<LpMain popUpType={'resetPassword'}/>} />
            <Route path="/verify_email/" element={<LpMain popUpType={'verifyEmail'} />} />
            <Route path="/verify_email/" element={<VerificationMailPopUp/>} />
            <Route exact path="/products/" element={<PpMain token={token} setToken={setToken}/>} />
            {/* <Route exact path="/ProductsSC/" element={<PpMainSC token={token} setToken={setToken}/>} />
            <Route exact path="/TestSC/" element={<TestSC/>} /> */}
            <Route 
                path="/settings/pictures/26032024" 
                element={
                <div>
                    <img src={`${process.env.PUBLIC_URL}${facebook}`} alt="Facebook"/>
                    <img src={`${process.env.PUBLIC_URL}${x}`} alt="Twitter"/>
                    <img src={`${process.env.PUBLIC_URL}${insta}`} alt="Instagram"/>
                    <img src={`${process.env.PUBLIC_URL}${linkedin}`} alt="LinkedIn"/>
                    <img src={`${process.env.PUBLIC_URL}${logo}`} alt="logo"/>
                </div>
                }
            />
            </Routes>
            </AuthProvider>
        </Router>
    )
}

export default App;
