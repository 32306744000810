import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Button } from 'antd';
import { CSSTransition } from 'react-transition-group';
import './css/Transitions.css'; // Créez ce fichier pour les styles de transition


import './css/PP_Section1.css'
import Header from '../Header/Header';
import Section1 from './PpSection1';
import AuthPopup from '../AuthentificationPopUp/AuthentificationPopUp';
import PP_SIDER from'./PpSider';

import AccountPopup from '../AccountPopUp/AccountPopUp';
import { AuthContext } from '../AuthContext/AuthContext';

const { Sider, Content } = Layout;

const PP_main = ({id}) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [productID, setProductID]= useState(id || 1)
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [isSiderVisible, setSiderVisibility] = useState(false);
  const getOrientation = () => {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  };

  const [orientation, setOrientation] = useState(getOrientation());
  
  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Clean up the event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  const handleOpenPopup = () => {
  setPopupVisibility(true);
  };

  const handleClosePopup = () => {
  setPopupVisibility(false);
  };

  const toggleSider = () => {
    setSiderVisibility(!isSiderVisible);
  };

    // Détermine si l'écran est de 576px ou inférieur
  const isSmallScreen = window.matchMedia('(max-width:850px)').matches || (window.matchMedia("(orientation: portrait)").matches) 

  return (
    <div>
      <Helmet>
        <title>Produits - Stratup</title>
        <meta name="description" content="Découvrez un univers d'investissement sans limite sur Stratup: objets de collection, biens d'exception, oeuvres d'art et bien plus encore!" />
      </Helmet> 
      
      {/*Option adaptée par produit
       <Helmet>
        <title>{product.name} - Stratup</title>
        <meta name="description" content={`Découvrez ${product.name} sur Stratup. ${product.description}`} />
      </Helmet> */}

      <Layout> 
        <Header handleClosePopup={handleClosePopup} handleOpenPopup={handleOpenPopup} />
        {isSmallScreen ? 
        (<></>) 
        : ( <Sider width="20%" style={{ background:'none'}}>
              <PP_SIDER productID={productID} setProductID={setProductID} loading="lazy" toggleSider={toggleSider} />
            </Sider>)
        }
        <Content>
          <CSSTransition
            in={isSmallScreen && isSiderVisible}
            timeout={300}
            classNames="sider-transition"
            unmountOnExit
          >
            <PP_SIDER productID={productID} setProductID={setProductID} loading="lazy" toggleSider={toggleSider} />
          </CSSTransition>
          <CSSTransition
            in={!isSmallScreen || !isSiderVisible}
            timeout={300}
            classNames="section1-transition"
            unmountOnExit
          >
            <Section1
              id='section1'
              handleClosePopup={handleClosePopup}
              handleOpenPopup={handleOpenPopup}
              toggleSider={toggleSider}
              productID={productID}
            />
          </CSSTransition>
        </Content>
      </Layout>
      {isAuthenticated ? 
        isPopupVisible &&<AccountPopup onClose={handleClosePopup} isPopupVisible={isPopupVisible} loading="lazy"/>
      : isPopupVisible && <AuthPopup onClose={handleClosePopup} isPopupVisible={isPopupVisible} loading="lazy"/>
      }
    </div>
  );
};

export default PP_main;



