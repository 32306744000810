import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import '../../css/style.css';

const contractAddress = '0x5fbdb2315678afecb367f032d93f642f64180aa3';

const contractABI = [
    {
        "inputs": [
          {
            "internalType": "string",
            "name": "_name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "_description",
            "type": "string"
          },
          {
            "internalType": "uint256",
            "name": "_totalShares",
            "type": "uint256"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "buyer",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "ShareBought",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "address",
            "name": "from",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "ShareTransferred",
        "type": "event"
      },
      {
        "inputs": [],
        "name": "availableShares",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "buyShare",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "description",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_owner",
            "type": "address"
          }
        ],
        "name": "getSharesOwned",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "name",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "owner",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "sharesOwned",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalShares",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
          }
        ],
        "name": "transferShare",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }
];

function App() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const [account, setAccount] = useState(null);
  const [buyerShares, setBuyerShares] = useState(0);

  useEffect(() => {
    const init = async () => {
      try {
        const localProvider = new ethers.providers.JsonRpcProvider('http://localhost:8545');
        setProvider(localProvider);

        const accounts = await localProvider.listAccounts();
        const signer = localProvider.getSigner(accounts[1]);
        setSigner(signer);
        setAccount(accounts[1]);

        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        setContract(contract);
      } catch (error) {
        console.error("Error initializing provider:", error);
      }
    };
    init();
  }, []);

  const buyShares = async (amount) => {
    if (contract) {
      try {
        const transaction = await contract.buyShare(amount, { value: ethers.utils.parseEther(amount.toString()) });
        await transaction.wait();
        console.log(`Bought ${amount} shares`);
      } catch (error) {
        console.error("Error buying shares:", error);
      }
    }
  };

  const transferShares = async (recipientAddress, amount) => {
    if (contract) {
      try {
        const transaction = await contract.transferShare(recipientAddress, amount);
        await transaction.wait();
        console.log(`Transferred ${amount} shares to ${recipientAddress}`);
      } catch (error) {
        console.error("Error transferring shares:", error);
      }
    }
  };

  const getShares = async () => {
    if (contract && account) {
      try {
        const buyerShares = await contract.getSharesOwned(account);
        setBuyerShares(buyerShares.toString());
      } catch (error) {
        console.error("Error getting shares:", error);
      }
    }
  };

  useEffect(() => {
    if (contract && account) {
      getShares();
    }
  }, [contract, account]);

  return (
    <div className="App">
        <h1>Luxury Collectible DApp</h1>
        <p>Connected account: {account}</p>
        <div>
          <h2>Buy Shares</h2>
          <button onClick={() => buyShares(5)}>Buy 5 Share</button>
        </div>
        <div>
          <h2>Transfer Shares</h2>
          <button onClick={() => transferShares("0x8626f6940E2eb28930eFb4CeF49B2d1F2C9C1199", 1)}>Transfer 1 Share</button>
        </div>
        <div>
          <h2>Your Shares</h2>
          <p>{buyerShares} shares</p>
        </div>
    </div>
  );
}

export default App;