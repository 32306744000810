// App.js
import React,{ useEffect, useState } from 'react';
import '../../css/style.css'
import { List } from 'antd';


const PP_Sider = ({productID, setProductID, toggleSider}) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        // Fonction pour récupérer les données depuis le backend
        const fetchProducts = async () => {
        try {
            const response = await fetch('https://stratup1.azurewebsites.net/get_products', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const data = await response.json(); // Attendre que la promesse soit résolue
            setProducts(data); // Mettre à jour l'état avec les données résolues
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
        };

        fetchProducts();
    }, []);

    if (loading || !Array.isArray(products) || products.length === 0) {
        return null; // ou return <div>Loading...</div> pour afficher un message de chargement
    }

    const isSmallScreen = window.matchMedia('(max-width:850px)').matches || (window.matchMedia("(orientation: portrait)").matches) 

    return (
        <List
                itemLayout="horizontal"
                dataSource={products}
                renderItem={(item, index) => (
                    <List.Item className='listItem'
                        style={{  
                            backgroundImage: `url(${item.PHOTOS.split(",")[0]})`,
                            borderRight: `${item.PRODUCT_ID}` === `${productID}` ? '5px solid #004343' : '0px solid #ffffff', // Bordure conditionnelle
                        }}
                        onClick={() => {
                            toggleSider();
                            setProductID(item.PRODUCT_ID)
                        }}
                        onMouseOver={e => {
                            e.currentTarget.style.backgroundColor = 'rgb(192, 199, 199, 0.5)' ;
                            e.currentTarget.style.transform = 'scale(1.03)';
                        }
                        } // Effet de zoom sur survol
                        onMouseOut={e => {
                            e.currentTarget.style.backgroundColor = 'rgb(192, 199, 199, 0.3)' ;
                            e.currentTarget.style.transform = 'scale(1)'
                        }
                        } // Retour à la taille normale
                    >   
                        <div style={{ marginLeft: '30%' , fontSize: '40%'}}>
                            <h1>{item.NAME} - {item.ARTIST}</h1>      
                            Levée de fonds: {item.PRICE ? item.PRICE.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits:0 }) : 'Prix non disponible'}
                        </div>
                </List.Item>
                )}
                
            />     
               
            );
};

export default PP_Sider;
